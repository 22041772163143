














































































































   import { Empresa } from '@/core/models/geral';
   import { OrdemServico } from '@/core/models/interna';
   import { EnumSituacaoOrdemServico } from '@/core/models/residuo/Enumerados';
   import { EmpresaService } from '@/core/services/geral';
   import { OrdemServicoService } from '@/core/services/interna';
   import { Vue, Component, Prop, Watch } from "vue-property-decorator";

   @Component
   export default class DetalharOrdemServico extends Vue {

   empresa: Empresa = new Empresa();
   item: OrdemServico = new OrdemServico();
   service: OrdemServicoService = new OrdemServicoService();

   overlay: boolean = false;

   mounted(){

      const id: number = Number(this.$route.params.id);
      this.overlay = true;
      this.service.ObterPorId(id, 'Contrato.Cliente.Contato, Contrato.Cliente.Endereco.Municipio.Estado, Base, Porto, Embarcacao, Motorista, Veiculo, Situacao, Transportadora, Servicos.Servico, Fotos').then(
         res => this.item = res.data,
         err => this.$swal('Aviso', err.message, 'error')
      )
      .finally(()=>{
         const empresaService = new EmpresaService();
         empresaService.ObterPorId(this.item.empresaId)
            .then(
               res =>  this.empresa = res.data,
               err => this.$swal('Aviso', err.message, 'error')
         );
         this.overlay = false;
      });    

   } 

   TextoMarcaDagua(){
      return this.item.situacaoId == EnumSituacaoOrdemServico.Cancelado ? 'Cancelado' : '';
   }
}

